import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { useDate } from "../../hooks/useDate";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const PreviewList = () => {
  const classes = useStyles();
  const { datetimeToClient } = useDate();
  const [loading, setLoading] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchPreviews = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/schedules-preview");
        setPreviews(data);
        console.log("Dados de previews recebidos:", data); // Adiciona este log
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPreviews();
  }, []);

  // Função para abrir o modal de confirmação
  const handleOpenModal = (id) => {
    setSelectedId(id);
    setOpenModal(true);
  };

  // Função para fechar o modal de confirmação
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId(null);
  };

  // Função para excluir agendamento
  const handleDelete = async () => {
    try {
      await api.delete(`/schedules-preview/${selectedId}`);
      setPreviews((prevPreviews) => prevPreviews.filter((preview) => preview.id !== selectedId));
      handleCloseModal();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Paper className={classes.mainPaper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Lista de Contatos</TableCell>
            <TableCell align="center">WhatsApp</TableCell>
            <TableCell align="center">Fila</TableCell>
            <TableCell align="center">Concluído</TableCell>
            <TableCell align="center">Agendamento</TableCell>
            <TableCell align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previews.map((preview) => (
            <TableRow key={preview.id}>
              <TableCell align="center">{preview.contactList?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.whatsapp?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.queue?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.isCompleted ? "Sim" : "Não"}</TableCell>
              <TableCell align="center">{datetimeToClient(preview.scheduledAt) || "Sem agendamento"}</TableCell>
              <TableCell align="center">
                <IconButton size="small" onClick={() => handleOpenModal(preview.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton size="small">
                  <DescriptionIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {loading && <TableRowSkeleton columns={6} />}
        </TableBody>
      </Table>

      {/* Modal de Confirmação */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Você tem certeza que deseja excluir este agendamento?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PreviewList;

import React, { useContext, useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Delete, TransferWithinAStation, Schedule } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import ScheduleModal from "../ScheduleModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import Tooltip from '@material-ui/core/Tooltip';
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles(theme => ({
	iconButton: {
		color: theme.palette.primary.mainReverseByWhite,
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
	},
}));

const TicketOptionsMenu = ({ ticket }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [contactId, setContactId] = useState(null);
	const [deleteTicketButton, setDeleteTicketButton] = useState("enabled");
	const { user } = useContext(AuthContext);
	const isMounted = useRef(true);
	const theme = useTheme();
	const classes = useStyles(theme);
	const { getAll } = useSettings(); // Obtenha a função getAll do hook

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const settings = await getAll(); // Use getAll para buscar as configurações
				const deleteTicketButtonSetting = settings.find((s) => s.key === "deleteTicketButton");
				setDeleteTicketButton(deleteTicketButtonSetting ? deleteTicketButtonSetting.value : "enabled");
			} catch (error) {
				console.error("Erro ao buscar configurações:", error);
			}
		};

		fetchSettings();
	}, [getAll]);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
	};

	const handleOpenTransferModal = () => {
		setTransferTicketModalOpen(true);
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};

	const handleOpenScheduleModal = () => {
		setContactId(ticket.contact.id);
		setScheduleModalOpen(true);
	};

	const handleCloseScheduleModal = () => {
		setScheduleModalOpen(false);
		setContactId(null);
	};

	return (
		<>
			{/* Botão para agendar */}
			<Tooltip title={i18n.t("ticketOptionsMenu.schedule")}>
				<IconButton className={classes.iconButton} onClick={handleOpenScheduleModal}>
					<Schedule />
				</IconButton>
			</Tooltip>

			{/* Botão para transferir */}
			<Tooltip title={i18n.t("ticketOptionsMenu.transfer")}>
				<IconButton className={classes.iconButton} onClick={handleOpenTransferModal}>
					<TransferWithinAStation />
				</IconButton>
			</Tooltip>

			{/* Botão para excluir */}
			{deleteTicketButton === "disabled" && (
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<Tooltip title={i18n.t("ticketOptionsMenu.delete")}>
							<IconButton className={classes.iconButton} onClick={handleOpenConfirmationModal}>
								<Delete />
							</IconButton>
						</Tooltip>
					)}
				/>
			)}

			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>

			<TransferTicketModalCustom
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
			/>

			<ScheduleModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				aria-labelledby="form-dialog-title"
				contactId={contactId}
			/>
		</>
	);
};

export default TicketOptionsMenu;

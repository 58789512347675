import React, { useState, useEffect } from "react";
import qs from "query-string";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import "./style.css";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginHeader from "../../components/LoginHeader";
import InfoSlider from "../../components/InfoSlider";


const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Connectar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    backgroundColor: theme.palette.drawerBackground,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    "&.MuiButton-root": {
      margin: "20px 0px 16px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "90", 
    },
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 2),
    WebkitTextFillColor: "#FFF",
    width: "50%",
    textTransform: "capitalize"
  },

  text: {
    color: theme.palette.primary.main,
  },

  darkerTexts: {
    color: theme.palette.primary.third,
  },

  linkColor: {
    color: theme.palette.primary.links,
  },

  inputFocus: {
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.main} !important`,
    border: "1px solid transparent !important",
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = {
    name: "",
    email: "",
    phone: "",
    password: "",
    planId: "",
  };

  const [user] = useState(initialState);
  const dueDate = moment().add(3, "day").format();
  const handleSignUp = async (values) => {
    Object.assign(values, { recurrence: "MENSAL" });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: "t" });
    Object.assign(values, { campaignsEnabled: true });
    try {
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const [plans, setPlans] = useState([]);
  const { getPlanList } = usePlans()

  useEffect(() => {
    const fetchData = async () => {
      const planList = await getPlanList({ listPublic: "false" });

      setPlans(planList);
    }
    fetchData();
  }, []);

  return (
    <div className={`geral ${classes.paper}`}>
      <LoginHeader />
      <section className="cmp-signup__section">
        <div className="cmp-login__max-container">
          <div className={"container-signup"}>
            <InfoSlider />
            <div className={"paper cmp-login__box"}>

              <h4 className={"cmp-login__form-title " + classes.text}>Criar uma conta</h4>
              <div>
                <span className={"span cmp-login__form-description " + classes.darkerTexts}>
                  Comece seu <b>teste GRATUITO</b> de 3 dias do Connectar em
                  apenas 3 etapas!{" "}
                  <b>Não se preocupe, nós não pedimos dados do seu cartão.</b>
                </span>
              </div>
              <Formik
                initialValues={user}
                enableReinitialize={true}
                validationSchema={UserSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    handleSignUp(values);
                    actions.setSubmitting(false);
                  }, 400);
                }}

              >
                {({ touched, errors, isSubmitting }) => (
                  <Form className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className={"cmp-signup_labels " + classes.darkerTexts}>Qual o seu nome?</label>
                        <Field
                          as={TextField}
                          autoComplete="name"
                          name="name"
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          id="name"
                          required
                          inputProps={{
                            style: {
                              borderRadius: "0px",
                              height: "30px",
                              padding: ".375rem .75rem",
                              boxShadow: "none",
                              backgroundColor: "white",
                              border: "1px solid #dee2e6",
                              transition: "border 0.3s ease, box-shadow 0.3s ease",
                            },
                          }}
                          onFocus={(e) => {
                            e.target.classList.add(classes.inputFocus);
                          }}
                          onBlur={(e) => {
                            e.target.classList.remove(classes.inputFocus);
                            e.target.style.border = "1px solid #dee2e6";
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <label className={"cmp-signup_labels " + classes.darkerTexts}>Seu e-mail</label>
                        <Field
                          as={TextField}
                          fullWidth
                          id="email"
                          name="email"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          autoComplete="email"
                          required
                          inputProps={{
                            style: {
                              borderRadius: "0px",
                              height: "30px",
                              padding: ".375rem .75rem",
                              backgroundColor: "white",
                              boxShadow: "none",
                              border: "1px solid #dee2e6",
                              transition: "border 0.3s ease, box-shadow 0.3s ease",
                            },
                          }}
                          onFocus={(e) => {
                            e.target.classList.add(classes.inputFocus);
                          }}
                          onBlur={(e) => {
                            e.target.classList.remove(classes.inputFocus);
                            e.target.style.border = "1px solid #dee2e6";
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <label className={"cmp-signup_labels " + classes.darkerTexts}>Seu número de Whatsapp</label>
                        <Field
                          as={TextField}
                          margin="dense"
                          fullWidth
                          id="phone"
                          name="phone"
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          autoComplete="phone"
                          required
                          inputProps={{
                            style: {
                              borderRadius: "0px",
                              height: "30px",
                              padding: ".375rem .75rem",
                              backgroundColor: "white",
                              boxShadow: "none",
                              border: "1px solid #dee2e6",
                              transition: "border 0.3s ease, box-shadow 0.3s ease",
                            },
                          }}
                          onFocus={(e) => {
                            e.target.classList.add(classes.inputFocus);
                          }}
                          onBlur={(e) => {
                            e.target.classList.remove(classes.inputFocus);
                            e.target.style.border = "1px solid #dee2e6";
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <label className={"cmp-signup_labels " + classes.darkerTexts}>Sua senha</label>
                        <Field
                          as={TextField}
                          fullWidth
                          name="password"
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          required
                          inputProps={{
                            style: {
                              borderRadius: "0px",
                              height: "30px",
                              boxShadow: "none",
                              padding: ".375rem .75rem",
                              backgroundColor: "white",
                              border: "1px solid #dee2e6",
                              transition: "border 0.3s ease, box-shadow 0.3s ease",
                            },
                          }}
                          onFocus={(e) => {
                            e.target.classList.add(classes.inputFocus);
                          }}
                          onBlur={(e) => {
                            e.target.classList.remove(classes.inputFocus);
                            e.target.style.border = "1px solid #dee2e6";
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className={"cmp-signup_labels " + classes.darkerTexts}>Plano</label>
                        <Field
                          as={Select}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          id="plan-selection"
                          label="Plano"
                          name="planId"
                          required
      
                          inputProps={{
                            style: {
                              borderRadius: "0px",
                              height: "30px",
                              padding: ".375rem .75rem",
                              backgroundColor: "white",
                              border: "1px solid #dee2e6",
                              boxShadow: "none",
                              transition: "border 0.3s ease, box-shadow 0.3s ease",
                            },
                          }}
                        >
                          {plans.map((plan, key) => (
                            <MenuItem key={key} value={plan.id} className={classes.darkerTexts}>
                              {plan.name} - Atendentes: {plan.users} - WhatsApp:{" "}
                              {plan.connections} - Filas: {plan.queues} - R${" "}
                              {plan.value}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      margin="dense"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {i18n.t("signup.buttons.submit")}
                    </Button>
                    <div className={"container-footer-form"}>
                      <p>
                        {i18n.t("signup.buttons.login")}
                        <Link
                          component={RouterLink}
                          tabIndex={0}
                          role={"button"}
                          aria-disabled={"false"}
                          to="/login"
                          style={{ color: "rgb(1, 41, 112)" }}
                        >
                          <span className={classes.linkColor}>{i18n.t("signup.buttons.enter")}</span>
                        </Link>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      <footer className="cmp-login__footer">
        <div className="cmp-login__max-container">
          <a className={classes.darkerTexts} href={"https://policies.google.com/privacy"} target={"_blank"}>
            Privacy Policy
          </a>
          <a className={classes.darkerTexts} href={"https://policies.google.com/terms"} target={"_blank"}>
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
};

export default SignUp;

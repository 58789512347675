import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import * as XLSX from 'xlsx';
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { CircularProgress, FormControl, Grid, IconButton, TextField, Tooltip, Typography, Chip } from "@material-ui/core";
import { UsersFilter } from "../../components/UsersFilter";
import { WhatsappsFilter } from "../../components/WhatsappsFilter";
import { StatusFilter } from "../../components/StatusFilter";
import useDashboard from "../../hooks/useDashboard";
import QueueSelect from "../../components/QueueSelect";
import moment from "moment";
import { Forward, Height, SaveAlt } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterList from "@material-ui/icons/FilterList";
import Ticket from "../../components/Ticket";
import TicketChart from "../../components/TicketChart";
import FilterComponent from "../../components/FilterComponent";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "transparent",
    margin: "0.5rem",
    padding: "1rem",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 
                0px 4px 10px rgba(0, 0, 0, 0.1)`, // Box-shadow mais leve
  },

  filterContainer: {
    padding: "1rem",
    backgroundColor: "transparent",
    border:`1px solid #00000020`,
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 
                0px 4px 10px rgba(0, 0, 0, 0.1)`, // Box-shadow mais leve
    borderRadius: "8px"
  },
  tableContainer: {
    backgroundColor: "transparent",
    margin: "1rem 0",
    padding: 0,
    display: "flex",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "none"
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: "auto",
    maxWidth: "167px",
  },
  exportButton: {
    marginRight: theme.spacing(1),
    width: "25px",
    height: "25px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
    color: theme.palette.primary.mainReverseByWhite,
    borderRadius: "8px"
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  headerText: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontSize: "16px"
  },
  filterItem: {
    width: '100%',
  },
  tableHead: {
    background: theme.palette.primary.secondGradient,
    color: "white",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "600"
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      fontFamily: "Poppins",
      fontSize: "12px",
    },
  },
  reportsTitle: {
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700"
  }
}));

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getReport } = useDashboard();
  const { user } = useContext(AuthContext);
  const dropdownRef = useRef(null); // Cria uma referência para o elemento
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [queueInfo, setQueueInfo] = useState({});
  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [queueIds, setQueueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/api/categories/list", {
          params: { companyId: user?.companyId },
        });
        console.log("Categorias recebidas da API:", response.data); // Adicione esta linha
        setCategories(response.data);

      } catch (err) {
        toastError(err);
      }
    };
    fetchCategories();
  }, [user?.companyId]);

  const countTicketsByQueue = (tickets) => {
    return tickets.reduce((acc, ticket) => {
      const queueName = ticket.queueName || 'Sem fila';

      if (acc[queueName]) {
        acc[queueName] += 1;
      } else {
        acc[queueName] = 1;
      }

      return acc;
    }, {});
  };
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await api.get("contacts", {
          params: { searchParam },
        });

        setOptions(response.data.contacts);
      } catch (err) {
        toastError(err);
      }
    };

    if (searchParam.length > 0) {
      fetchContacts();
    }
  }, [searchParam]);

  const exportarGridParaExcel = async () => {
    setLoading(true);
    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        categoryIds: JSON.stringify(categoryIds),
        dateFrom,
        dateTo,
        timeDifference, // Inclua aqui
        page: 1,
        pageSize: 9999999,
      });

      const ticketsData = data.tickets.map(ticket => ({
        id: ticket.id,
        Conexão: ticket.whatsappName,
        Contato: ticket.contactName,
        Usuário: ticket.userName,
        Fila: ticket.queueName,
        Status: ticket.status,
        ÚltimaMensagem: ticket.lastMessage,
        DataHoraAbertura: ticket.createdAt,
        DataHoraFechamento: ticket.closedAt || "",
        TempoDeAtendimento: ticket.supportTime,
        Categória: ticket.categoryName,
        nps: ticket.NPS,
        TempoDeEspera: ticket.timeDifference, // Inclua aqui

      }));
      const ws = XLSX.utils.json_to_sheet(ticketsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'RelatorioDeAtendimentos');
      XLSX.writeFile(wb, 'relatorio-de-atendimentos.xlsx');
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = async (page) => {
    setLoading(true);
    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        categoryIds: JSON.stringify(categoryIds),
        dateFrom,
        dateTo,
        timeDifference, // Inclua aqui
        page,
        pageSize,
      });
      const result = countTicketsByQueue(data.tickets);

      setQueueInfo(result);
      setTotalTickets(data.totalTickets.total);
      setTickets(data.tickets);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFilter(pageNumber);

  }, [pageNumber]);

  return (
    <div className={classes.mainContainer}>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", position: "relative", margin: "0  0 1rem 0" }}>
        <h3 className={classes.reportsTitle}>{i18n.t("reports.title")}</h3>
        <IconButton onClick={exportarGridParaExcel} className={classes.exportButton}>
          <SaveAlt />
        </IconButton>
      </div>

      <div>
        <FilterComponent
          classes={classes}
          loading={loading}
          setSearchParam={setSearchParam}
          setSelectedContactId={setSelectedContactId}
          setSelectedWhatsapp={setSelectedWhatsapp}
          setSelectedStatus={setSelectedStatus}
          queueIds={queueIds}
          setQueueIds={setQueueIds}
          setUserIds={setUserIds}
          options={options}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          categoryIds={categoryIds}
          setCategoryIds={setCategoryIds}
          timeDifference={timeDifference}
          setTimeDifference={setTimeDifference}
          categories={categories}
          handleFilter={handleFilter}
        />

      </div>
      <Paper className={classes.tableContainer}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="left">Canal</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Usuário</TableCell>
              <TableCell align="left">Fila</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Abertura</TableCell>
              <TableCell align="center">Fechado</TableCell>
              <TableCell align="center">T.Atendido</TableCell>
              <TableCell align="center">NPS</TableCell>
              <TableCell align="center">Tabulação</TableCell>
              <TableCell align="center">Qualidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton avatar columns={3} />
            ) : (
              <>
                {Array.isArray(tickets) && tickets.length > 0 ? (
                  tickets.map(ticket => (
                    <TableRow key={ticket.id} className={classes.tableRow}>
                      <TableCell align="center">
                        {ticket.id}
                        <Tooltip title="Acessar Ticket">
                          <Forward
                            onClick={() => { history.push(`/tickets/${ticket.uuid}`) }}
                            fontSize="small"
                            style={{
                              color: "#4caf50",
                              cursor: "pointer",
                              marginLeft: 10,
                              verticalAlign: "middle"
                            }}
                          />
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.whatsappName || "undefined"}>
                          <span>
                            {ticket.whatsappName?.length > 10 ? `${ticket.whatsappName.substring(0, 10)}...` : ticket.whatsappName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.contactName || "undefined"}>
                          <span>
                            {ticket.contactName?.length > 10 ? `${ticket.contactName.substring(0, 10)}...` : ticket.contactName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title={ticket.userName || "undefined"}>
                          <span>
                            {ticket.userName?.length > 10 ? `${ticket.userName.substring(0, 10)}...` : ticket.userName}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">{ticket.queueName}</TableCell>

                      <TableCell align="center">{ticket.status}</TableCell>

                      <TableCell align="center">
                        {new Date(ticket.createdAt).toLocaleDateString('pt-BR')}
                      </TableCell>

                      <TableCell align="center">
                        {ticket.closedAt}
                      </TableCell>

                      <TableCell align="center">{ticket.supportTime}</TableCell>
                      <TableCell align="center">{ticket.NPS}</TableCell>
                      <TableCell align="center">{ticket.categoryName}</TableCell>
                      <TableCell align="center">{ticket.timeDifference}</TableCell> {/* Nova célula */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">Nenhum ticket encontrado</TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>


        </Table>
      </Paper>

      {/* Paginação */}
      <div className={classes.pagination}>
        <Pagination
          count={Math.ceil(totalTickets / pageSize)}
          page={pageNumber}
          onChange={(event, value) => setPageNumber(value)}
        />
      </div>
    </div>
  );
};

export default Reports;

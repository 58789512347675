import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";

import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import "./style.css";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid, useMediaQuery } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginHeader from "../../components/LoginHeader";
import InfoSlider from "../../components/InfoSlider";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: "red",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    background: theme.palette.drawerBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    "&.MuiButton-root": {
      margin: "20px 0px 16px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "90",  
    },
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 2),
    WebkitTextFillColor: "#FFF",
    width: "50%",
    textTransform: "capitalize"
  },
  powered: {
    color: "white",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "30px",
    },
  },
  logo: {
    paddingBottom: "50px",
    width: "400px",
    marginBottom: theme.spacing(3),
  },
  sliderContainer: {
    width: '400px',
  },
  carousel: {
    width: '100%',
    height: '300px',
  },
  carouselItem: {
    width: '100%',
    height: '300px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: 'white',
    padding: '10px',
    backgroundRepeat: "no-repeat"
  },

  text: {
    color: theme.palette.primary.main,
  },

  darkerTexts: {
    color: theme.palette.primary.third,
  },

  linkColor: {
    color: theme.palette.primary.links,
  },

  inputFocus: {
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.main} !important`,
    border: "1px solid transparent !important",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const isMobile = useMediaQuery("(max-width: 900px)");
  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user, isMobile);
  };
  
  return (
    <div className={`geral ${classes.paper}`}>

      <LoginHeader />
      <div className="cmp-login__max-container-align">
        <section className="cmp-login__max-container">
          <div className={"container-right"}>
            <InfoSlider />
            <div className={"cmp-login__box"}>
              <div className="cmp-login__form-label-container">
                <h3 className={`cmp-login__form-title ${classes.text}`}>Faça login na sua conta</h3>
                <p className={`cmp-login__form-description ${classes.darkerTexts}`}>
                  Digite o seu E-mail e senha para fazer login
                </p>
              </div>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <label className={`cmp-login__label ${classes.darkerTexts}`} htmlFor="email">
                  E-mail
                </label>
                <div className="cmp-login__email-input-container">
                  <div className="cmp-login__email-icon">
                    <span className={`${classes.darkerTexts}`}>@</span>
                  </div>
 
                  <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    onChange={handleChangeInput}
                    autoComplete="email"
                    inputProps={{
                      style: {
                        borderRadius: "0px",
                        height: "30px",
                        padding: ".375rem .75rem",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid #dee2e6",
                        transition: "border 0.3s ease, box-shadow 0.3s ease",
                      },
                      className: {}
                    }}
                    onFocus={(e) => {
                      e.target.classList.add(classes.inputFocus);
                    }}
                    onBlur={(e) => {
                      e.target.classList.remove(classes.inputFocus);
                      e.target.style.border = "1px solid #dee2e6";
                    }}
                  />
                </div>
                <label className={`${classes.darkerTexts} cmp-login__label`} htmlFor="password">
                  Senha
                </label>
                <div className="cmp-login__password-input-container">
                  <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                    inputProps={{
                      style: {
                        borderRadius: "0px",
                        height: "30px",
                        padding: ".375rem .75rem",
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid #dee2e6",
                        transition: "border 0.3s ease, box-shadow 0.3s ease",
                      },
                    }}
                    onFocus={(e) => {
                      e.target.classList.add(classes.inputFocus);
                    }}
                    onBlur={(e) => {
                      e.target.classList.remove(classes.inputFocus);
                      e.target.style.border = "1px solid #dee2e6";
                    }}
                  />
                </div>

                <Grid container>
                  <Grid item>
                    <Link
                      href="#"
                      component={RouterLink}
                      to="/recovery-password"
                      className={classes.linkColor}
                      style={{
                        margin: '1rem 0 0 0 ',
                        fontWeigth: "500"
                      }}
                    >
                      {i18n.t("Esqueci a Senha")}
                    </Link>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{margin: "1rem 0"}}
                >
                  Login
                </Button>
                <div className={"container-footer-form"}>
                  <p className={classes.darkerTexts}>
                    Não tem conta?
                    <Link
                      component={RouterLink}
                      tabIndex={0}
                      role={"button"}
                      aria-disabled={"false"}
                      className={classes.linkColor}
                      to="/signup"
                       style={{
                        fontWeigth: "500"
                      }}
                    >
                      <span>Criar conta</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <footer className="cmp-login__footer">
        <div className="cmp-login__max-container">
          <a className={classes.darkerTexts} href={"https://policies.google.com/privacy"} target={"_blank"}>
            Privacy Policy
          </a>
          <a className={classes.darkerTexts} href={"https://policies.google.com/terms"} target={"_blank"}>
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";
import useWhatsApps from "../../hooks/useWhatsApps";
import useContactLists from "../../hooks/useContactLists";
import useQueues from "../../hooks/useQueues";
import api from "../../services/api";

const PreviewModal = ({ isOpen, onClose, onConfirm, onUpdate }) => {
  const [selectedConnection, setSelectedConnection] = useState("");
  const [selectedContactList, setSelectedContactList] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedQueueName, setSelectedQueueName] = useState(""); // Armazenar o nome da fila selecionada
  const [selectedConnectionName, setSelectedConnectionName] = useState(""); // Armazenar o nome da conexão selecionada
  const [scheduledAt, setScheduledAt] = useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [contactLists, setContactLists] = useState([]);
  const [queues, setQueues] = useState([]);
  const [ticketsToConfirm, setTicketsToConfirm] = useState([]);
  const [isConfirmingChanges, setIsConfirmingChanges] = useState(false);
  const [selectedConflicts, setSelectedConflicts] = useState([]); // Para armazenar os conflitos selecionados

  const { whatsApps, loading: loadingWhatsApps } = useWhatsApps();
  const { list: fetchContactLists } = useContactLists();
  const { findAll: fetchQueues } = useQueues();

  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");

      const contactData = await fetchContactLists({ companyId });
      setContactLists(contactData);

      const queueData = await fetchQueues({ companyId });
      setQueues(queueData);
    } catch (error) {
      toast.error("Erro ao buscar dados.");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    if (!selectedConnection || !selectedContactList || !selectedQueue) {
      toast.error("Por favor, selecione todas as opções.");
      return;
    }

    try {
      const formattedScheduledAt = moment(scheduledAt).format('YYYY-MM-DD HH:mm:ss');

      // Enviando requisição para a rota preview-create
      const response = await api.post("/preview-create", {
        whatsappId: selectedConnection,
        contactListId: selectedContactList,
        queueId: selectedQueue,
        status: "pending",
        scheduledAt: formattedScheduledAt,
      });

      if (response.data.tickets) {
        setTicketsToConfirm(response.data.tickets);
        setIsConfirmingChanges(true);
        console.log("Chamada para atualibbbbbbbbbbbbbzar a lista"); // Adicione este log

      } else {
        toast.success("Operação agendada com sucesso!");
        onConfirm();
        console.log("Chamada para atualizaaaaaaaaaaaaaaaaaaaaaar a lista"); // Adicione este log
        onUpdate(); 
        onClose();
      }
    } catch (error) {
      toast.error("Erro ao confirmar operação.");
    }
  };

  const handleConfirmChanges = async () => {
    try {
        const formattedScheduledAt = moment(scheduledAt).format('YYYY-MM-DD HH:mm:ss');

        // Enviar apenas os conflitos selecionados
        const conflictsToConfirm = ticketsToConfirm.filter((ticket, index) => selectedConflicts.includes(index));
        
        console.log("Conflitos selecionados para enviar:", conflictsToConfirm);

        await api.post("/preview-confirm-changes", {
            ticketsToConfirm: conflictsToConfirm,
            contactListId: selectedContactList,
            whatsappId: selectedConnection,
            queueId: selectedQueue,
            status: "pending",
            scheduledAt: formattedScheduledAt,
        });

        toast.success("Mudanças confirmadas e agendamento criado com sucesso!");
        setIsConfirmingChanges(false);
        setTicketsToConfirm([]);
        onConfirm();
        onClose();
    } catch (error) {
        toast.error("Erro ao confirmar mudanças.");
    }
};

  const handleCancelChanges = () => {
    setIsConfirmingChanges(false);
    setTicketsToConfirm([]);
    toast.info("Mudanças canceladas.");
  };

  const handleConflictSelection = (index) => {
    setSelectedConflicts((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Selecione Opções</DialogTitle>
      <DialogContent>
        {/* Seleção da Conexão */}
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Conexão</InputLabel>
          <Select
            value={selectedConnection}
            onChange={(e) => {
              setSelectedConnection(e.target.value);
              const selected = whatsApps.find((wpp) => wpp.id === e.target.value);
              setSelectedConnectionName(selected ? selected.name || selected.number : "");
            }}
            disabled={loadingWhatsApps}
          >
            {loadingWhatsApps ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              whatsApps.map((whatsApp) => (
                <MenuItem key={whatsApp.id} value={whatsApp.id}>
                  {whatsApp.name || whatsApp.number}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        {/* Seleção da Lista de Contatos */}
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Lista de Contatos</InputLabel>
          <Select
            value={selectedContactList}
            onChange={(e) => setSelectedContactList(e.target.value)}
          >
            {contactLists.length > 0 ? (
              contactLists.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Nenhuma lista encontrada</MenuItem>
            )}
          </Select>
        </FormControl>

        {/* Seleção da Fila */}
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Fila</InputLabel>
          <Select
            value={selectedQueue}
            onChange={(e) => {
              setSelectedQueue(e.target.value);
              const selected = queues.find((q) => q.id === e.target.value);
              setSelectedQueueName(selected ? selected.name : "");
            }}
          >
            {queues.length > 0 ? (
              queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Nenhuma fila encontrada</MenuItem>
            )}
          </Select>
        </FormControl>

        {/* Seleção da Data e Hora de Agendamento */}
        <TextField
          label="Agendar para"
          type="datetime-local"
          value={scheduledAt}
          onChange={(e) => setScheduledAt(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          style={{ marginBottom: "1rem" }}
        />

        {/* Mostrando conflitos se existirem */}
        {isConfirmingChanges && (
          <div>
            <h4>Conflitos encontrados:</h4>
            <ul>
              {ticketsToConfirm.map((ticket, index) => (
                <li key={index}>
                  <Checkbox
                    checked={selectedConflicts.includes(index)}
                    onChange={() => handleConflictSelection(index)}
                  />
                  <ListItemText
                    primary={`Contato: ${ticket.contactName} - Fila Atual: ${ticket.currentQueueName || "N/A"} - Nova Fila: ${selectedQueueName}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {isConfirmingChanges ? (
          <>
            <Button onClick={handleCancelChanges} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmChanges} color="primary">
              Confirmar Mudanças
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;

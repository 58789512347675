import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box, Divider } from '@material-ui/core';
import { GroupAdd, GroupWork, People, Block, Gavel, EmojiPeople } from '@material-ui/icons';
import CreateGroupComponent from '../../components/createGroup';
import InProgressComponent from '../../components/emProgresso';
import ManipulateGroupsComponent from '../../components/ManipularGroups';
import SaudacaoComponent from '../../components/SaudacaoComponent';
import ManipulateUsersComponent from '../../components/ManipularUsuarios';



// Função auxiliar para renderizar as abas
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const GroupAndParticipantsPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
        Grupos e Participantes
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab icon={<GroupAdd />} label="Grupos e Participantes" />
        <Tab icon={<GroupWork />} label="Manipular Grupos" />
        <Tab icon={<People />} label="Manipular Usuários" />
        {/* <Tab icon={<Block />} label="Números Banidos" /> */}
        <Tab icon={<EmojiPeople />} label="Menssagem de Saudação" />
        {/* <Tab icon={<Gavel />} label="Palavras Proibidas" /> */}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <CreateGroupComponent />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ManipulateGroupsComponent />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ManipulateUsersComponent />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <SaudacaoComponent />
      </TabPanel>
    
    </div>
  );
};

export default GroupAndParticipantsPage;
